import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Live_Video/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "The MJPEG Videostream",
  "path": "/Quick_Installation/Live_Video/MJPEG/",
  "dateChanged": "2017-11-23",
  "author": "Mike Polinowski",
  "excerpt": "How to get a Live Video Stream without Installing a Video Plugin?",
  "image": "./QI_SearchThumb_LiveVideo_MJPEG.png",
  "social": "/images/Search/QI_SearchThumb_LiveVideo.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Live_Video_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='The MJPEG Videostream' dateChanged='2017-11-23' author='Mike Polinowski' tag='INSTAR IP Camera' description='How to get a Live Video Stream without Installing a Video Plugin?' image='/images/Search/QI_SearchThumb_LiveVideo.png' twitter='/images/Search/QI_SearchThumb_LiveVideo.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Live_Video/MJPEG/' locationFR='/fr/Quick_Installation/Live_Video/MJPEG/' crumbLabel="MJPEG" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "how-to-get-a-live-video-stream-without-installing-a-video-plugin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-get-a-live-video-stream-without-installing-a-video-plugin",
        "aria-label": "how to get a live video stream without installing a video plugin permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to get a Live Video Stream without Installing a Video Plugin?`}</h2>
    <p>{`With Windows 10 Microsoft offers a new web browser to substitute the old Internet Explorer. Due to the omission of plugin support, using your HD IP camera becomes a little bit more challenging. But fear not, your camera is still going to work under Windows 10 and the following paragraphs will show you how.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3e04cb5c4bf55e2d2cc1f1344d72c6b4/00d43/Microsoft-Edge_00.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABtklEQVQY0wGrAVT+AAtwwQ5zww1stxJfnA1mrg5zxBB0whJ1wxF1xBF1xA90xBV4xhF2xg10xg92yAtxxBqE1B2K2RZ+zxh8ygAtMTUuMjYvMzYwNDcyNjk0Oj03PEA6P0NARkpFTE9KUVRQV1pYXmJhZmppbXJJT1G4qrjJq8SDe3Zya2AARUJARUNBRENASkhGSEZFT01KUlBOT05MRkVCTk1LVFNQW1pXYmBfbGtqZmVlVFNQzaG405+5gnltcWZXAENCQkNDQ0VGRUZGRkdHR0dHR01NTFBRUE1OTU1PTlRWVVxeXWRlZXJ0dVdYWWNiYoTE2ZC+ysW6u7q6uQA/Q0dBQUFDQ0NGR0ZERUQ/QEBERURJSklPUE9SU1JZWlphYmFoaGh3eHdRUE9weHux19+twsrHtLy/sboAJVN4MjU3Nzc2OTs6PkA/Q0VESUtKUlRTWlxbZGZlbW9ud3l4gIKBkJOSSktLnaCf18rWybvCwMTBy8jKADU4OjY3Nzk5OT4+PkJDQklKSVBSUVlbWmRmZXBycXx+fYiKiZKUk5+hoEZGRrq8u9vR1se8wsHDws/Pz17XqqyIhhJSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3e04cb5c4bf55e2d2cc1f1344d72c6b4/e4706/Microsoft-Edge_00.avif 230w", "/en/static/3e04cb5c4bf55e2d2cc1f1344d72c6b4/d1af7/Microsoft-Edge_00.avif 460w", "/en/static/3e04cb5c4bf55e2d2cc1f1344d72c6b4/7f308/Microsoft-Edge_00.avif 920w", "/en/static/3e04cb5c4bf55e2d2cc1f1344d72c6b4/445fc/Microsoft-Edge_00.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3e04cb5c4bf55e2d2cc1f1344d72c6b4/a0b58/Microsoft-Edge_00.webp 230w", "/en/static/3e04cb5c4bf55e2d2cc1f1344d72c6b4/bc10c/Microsoft-Edge_00.webp 460w", "/en/static/3e04cb5c4bf55e2d2cc1f1344d72c6b4/966d8/Microsoft-Edge_00.webp 920w", "/en/static/3e04cb5c4bf55e2d2cc1f1344d72c6b4/a5d4d/Microsoft-Edge_00.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3e04cb5c4bf55e2d2cc1f1344d72c6b4/81c8e/Microsoft-Edge_00.png 230w", "/en/static/3e04cb5c4bf55e2d2cc1f1344d72c6b4/08a84/Microsoft-Edge_00.png 460w", "/en/static/3e04cb5c4bf55e2d2cc1f1344d72c6b4/c0255/Microsoft-Edge_00.png 920w", "/en/static/3e04cb5c4bf55e2d2cc1f1344d72c6b4/00d43/Microsoft-Edge_00.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3e04cb5c4bf55e2d2cc1f1344d72c6b4/c0255/Microsoft-Edge_00.png",
              "alt": "Microsoft Edge Download",
              "title": "Microsoft Edge Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The MJPEG stream does not rely on plugins and runs without problems inside Microsoft Edge. Please be aware that the MJPEG stream uses more bandwidth resources on your network then the h.264 stream. If images from your camera arrive too late, due to this Ethernet traffic jam, you might experience a flicker inside the live video. Please go to Multimedia / Video and reduce the streams resolution to prevent this effect.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9bfaa8b4540ae2fa3e63f6b5c78b0340/00d43/Microsoft-Edge_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.56521739130435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB2ElEQVQoz33Qz0/aYBjA8UdhB2P8nc3MmOyPWKIJbtnBmB1YYvTkYXjawZu3/Q07LGoiUC0DZEMFS2lLO4qyUfdDiZpdlN8GJEY4EBKRgGLBdpYaI9r45Ht4nzf5JG9eyOVy+Xy+WCwen2T2w5Ho4aFUQrmDaDQWT8RisUgkEo/HoVKplMvlUql0Xji9PDsVqrxYqypX5a/KperFeaFQkAmI8giCM5qa+RdGwyk0nEJCyYcthJL6UHL9KCMLnuclLNQXDR5Qm8iuJbrbxqgWcDC6pBAXIDdn6XJ2ZYL9K4ri1T38muB6bMyLZV+nle6Qs3g6rHR9vTmAEdNtbCthkuteYvqX2b5v3i4r3Wah2yyedoun1Uy1mqkWM/UEJWDe+X4j2IBFQeJjZsfQ9MdmwxoYsIYQHBbdsOhWoQTo1x7g+oxj7Bhq15CbgwQnN0BwGurX06/fAcGbHsG1Wu0Dt2c6SJCpjCedlaPSWTadHXL/BCOmNhFqRSzUX65l/rzBf2i9W1p2+7Z33q3nNgYMWBPiUqNumHfqFLAovnSsw2e7as4Bs6sN6bFndl+v3dfyhQKUmPAp/fZUYPcV5n9LcSNk4G7DRGDSH9T5g6PMby21+WlnXybX+D9ZNgEoCELa+gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9bfaa8b4540ae2fa3e63f6b5c78b0340/e4706/Microsoft-Edge_01.avif 230w", "/en/static/9bfaa8b4540ae2fa3e63f6b5c78b0340/d1af7/Microsoft-Edge_01.avif 460w", "/en/static/9bfaa8b4540ae2fa3e63f6b5c78b0340/7f308/Microsoft-Edge_01.avif 920w", "/en/static/9bfaa8b4540ae2fa3e63f6b5c78b0340/445fc/Microsoft-Edge_01.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9bfaa8b4540ae2fa3e63f6b5c78b0340/a0b58/Microsoft-Edge_01.webp 230w", "/en/static/9bfaa8b4540ae2fa3e63f6b5c78b0340/bc10c/Microsoft-Edge_01.webp 460w", "/en/static/9bfaa8b4540ae2fa3e63f6b5c78b0340/966d8/Microsoft-Edge_01.webp 920w", "/en/static/9bfaa8b4540ae2fa3e63f6b5c78b0340/a5d4d/Microsoft-Edge_01.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9bfaa8b4540ae2fa3e63f6b5c78b0340/81c8e/Microsoft-Edge_01.png 230w", "/en/static/9bfaa8b4540ae2fa3e63f6b5c78b0340/08a84/Microsoft-Edge_01.png 460w", "/en/static/9bfaa8b4540ae2fa3e63f6b5c78b0340/c0255/Microsoft-Edge_01.png 920w", "/en/static/9bfaa8b4540ae2fa3e63f6b5c78b0340/00d43/Microsoft-Edge_01.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9bfaa8b4540ae2fa3e63f6b5c78b0340/c0255/Microsoft-Edge_01.png",
              "alt": "Microsoft Edge Download",
              "title": "Microsoft Edge Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Start the Microsoft Edge browser and type in your camera´s IP address into the address bar. If Edge is your default browser, simply use the INSTAR camera tool and double click on your camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1d1a397c6b71ea3d4b7a6db35baa4930/00d43/Microsoft-Edge_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACb0lEQVQoz1XRT0/acBjAcV6IO9i1rC1Ify3taKEgrS0tos5J2CIapmHGTC7uPWy7zZcgaDRyUKcH/slJWmsUVhIT/joRbx7cwcwXsNhJzD73b54nz2O7vb398/Bwf39/9/uu9euyd3PT6/eve71+v3890BvodruXlk6nc3V1ZdM07fvaWmZjo1772cnlu0flXrXWaLfr9Xqz2Wy1Wu12uzvQaDSazebp6alhGOfn57b19fTq6ucv375+Wl5emX6bejO9MhtPLi0tLi4mk8mP/0smk6lUKp/Pl8vlUqlk29ra2t/fP/hxgKLoCEnacRxCEAiC7HY7giAwDNvtdgzDEARBURTDMBRFDcO4uLgwTdOWTqe3t7f39vYIglBVNRwOh1VVluVwOCxJEsMwHo/H5QIOh4sgSAzDAAC6rpumWa1WH+PNzc1sNothGMuyE5ZIJDI1NaUoiizLiqKwLOA4B8tSQ0MvcBw/OTl5ijOZTDab3dnZYRhGEAS/3x8IBASLKIqhUCgYFMbHX0ejlCiSFEVTFKVpWr1ef4xzudzh4eHu7u7o6GgikfhgicfjCwsLc3NzMzMzsVgsGn03O5uIxd7Pz8/7fL5KpfIUa5p2fHxcLBZp2i2KQVmWJGlsbEwIhWRBEAAAFEWh6CsYfokgyPDwsNPpfF67UqmUy+VCIe92M4GA6PUGeF7w+YI8HxQEQZIkVVUZhgEAEARBWnRdf5p8dnZWrVZLpSOadkxOuiKRkUjEOTHhUhQXRVEkSQIA/v0MhmEIgnAcf762bikUijzPeTwjXi/gOMBxBMsCcsBtoWkaAMDzvGEYpmnWarW/Rp9NNqkCKvQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1d1a397c6b71ea3d4b7a6db35baa4930/e4706/Microsoft-Edge_02.avif 230w", "/en/static/1d1a397c6b71ea3d4b7a6db35baa4930/d1af7/Microsoft-Edge_02.avif 460w", "/en/static/1d1a397c6b71ea3d4b7a6db35baa4930/7f308/Microsoft-Edge_02.avif 920w", "/en/static/1d1a397c6b71ea3d4b7a6db35baa4930/445fc/Microsoft-Edge_02.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1d1a397c6b71ea3d4b7a6db35baa4930/a0b58/Microsoft-Edge_02.webp 230w", "/en/static/1d1a397c6b71ea3d4b7a6db35baa4930/bc10c/Microsoft-Edge_02.webp 460w", "/en/static/1d1a397c6b71ea3d4b7a6db35baa4930/966d8/Microsoft-Edge_02.webp 920w", "/en/static/1d1a397c6b71ea3d4b7a6db35baa4930/a5d4d/Microsoft-Edge_02.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1d1a397c6b71ea3d4b7a6db35baa4930/81c8e/Microsoft-Edge_02.png 230w", "/en/static/1d1a397c6b71ea3d4b7a6db35baa4930/08a84/Microsoft-Edge_02.png 460w", "/en/static/1d1a397c6b71ea3d4b7a6db35baa4930/c0255/Microsoft-Edge_02.png 920w", "/en/static/1d1a397c6b71ea3d4b7a6db35baa4930/00d43/Microsoft-Edge_02.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1d1a397c6b71ea3d4b7a6db35baa4930/c0255/Microsoft-Edge_02.png",
              "alt": "Microsoft Edge Download",
              "title": "Microsoft Edge Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now click on the h.264 / MJPEG toggle to activate the MJPEG stream.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/47d9239ac5ea317fe1248e3868fa9719/00d43/Microsoft-Edge_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC50lEQVQozwHcAiP9AOfn5/Pz8/Dw8O7u7tra2t/g4ODk5ODk5ODj4+Lh4eLi4uHh4eHh4eHh4eHh4dzc3N3d3dra2tfX19zc3ADExcWOj4+foKDGxcXHx8fMyMfSt7bWrKvTurvFycrBwMDIyMjJycnLy8vLy8vHx8jNzc3Ly8vMzMzQ0M8AoKChc3N0fn9+ZmZkV1dWbXN8kIifinuSpZuoq7C2tbS2r6+xrq2vra6wv7++r66ti4yKhIaGenx9fH6AAKSkpK+vr6KhoRUVEl5hZ3mQsnqXvZyxy9DV29fX2NXV1MXFxb28vtPS0tza18jGw5aWlmdpb0pPVUpPVQCgoKClpaWio6OGh4m7xdWmtcu7xNPb3N/d3Nzk4+Ha2dfExMPLy8vb2Na9vLqdnZ5yc3laXGNHS1JITVIAoKCgoJ+fsrKztri6vsHF4eDfx8bGzMzNxMXI1tXV1dTTzs7Ozs3M1NDMo6Ske32BXmBkTU9UOTxBP0JHAJ+fn6SkpLKzs8LExeHg2+Dg3MTGyMPHzb3Cxr7Aw7u/w7O4v7a6wK6zuZOYoHR3fVtfZ01PVTk7QUNFSwC4uLisrKzBwsHAwcCdnZuBgH90dXWTmZ7M1NvKz9O8wMSxtrupsrugq7eXoKuLlKCUmZ1xc3NoaGNtbGgAxcXFwsLCuLi4NDQ1JSUmMjExGxseQD9CgYODnJ6fyszOwsTEvL29srSzpKanlJianpyVeHl1SEpKREdHAMLCwry8vK6trCEfGRESEyAgHyIgICgmJhwdITY5PElNTVhdYXF3em1ydFldYEpQUz9CRT0+PyAkJysvMgDKysrNzc2ysrAlJxofIxkeJBseJRwnLSYyOTZKUEtPVlNTWVdcY2NaYWNPVVZCSUtDRkY6PTonKig1NjUAwsLCxMTEqamnJCQXKyoZLjUoLTgrOUQ4SFNMUFlQUVpVT1ZTTVRRSlFPQUhHOD49Njs5MjYyJCclMjUzC86bTKRfrp8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/47d9239ac5ea317fe1248e3868fa9719/e4706/Microsoft-Edge_03.avif 230w", "/en/static/47d9239ac5ea317fe1248e3868fa9719/d1af7/Microsoft-Edge_03.avif 460w", "/en/static/47d9239ac5ea317fe1248e3868fa9719/7f308/Microsoft-Edge_03.avif 920w", "/en/static/47d9239ac5ea317fe1248e3868fa9719/445fc/Microsoft-Edge_03.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/47d9239ac5ea317fe1248e3868fa9719/a0b58/Microsoft-Edge_03.webp 230w", "/en/static/47d9239ac5ea317fe1248e3868fa9719/bc10c/Microsoft-Edge_03.webp 460w", "/en/static/47d9239ac5ea317fe1248e3868fa9719/966d8/Microsoft-Edge_03.webp 920w", "/en/static/47d9239ac5ea317fe1248e3868fa9719/a5d4d/Microsoft-Edge_03.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/47d9239ac5ea317fe1248e3868fa9719/81c8e/Microsoft-Edge_03.png 230w", "/en/static/47d9239ac5ea317fe1248e3868fa9719/08a84/Microsoft-Edge_03.png 460w", "/en/static/47d9239ac5ea317fe1248e3868fa9719/c0255/Microsoft-Edge_03.png 920w", "/en/static/47d9239ac5ea317fe1248e3868fa9719/00d43/Microsoft-Edge_03.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/47d9239ac5ea317fe1248e3868fa9719/c0255/Microsoft-Edge_03.png",
              "alt": "Microsoft Edge Download",
              "title": "Microsoft Edge Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The MJPEG stream is now active (MJPEG has to be red) and should display a video. You can adjust the quality of the MJPEG stream under Multimedia/Video - Choose a smaller resolution if you experience a image flicker due to bandwidth restrictions.`}</p>
    <h3 {...{
      "id": "the-h264-stream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-h264-stream",
        "aria-label": "the h264 stream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The h.264 Stream`}</h3>
    <p>{`The h.264 stream is a lot more efficient compared to the MJPEG stream and can usually be displayed in a higher quality without overly affecting your network. But as mentioned above, the necessary plugin for the h.264 stream is no longer supported by Microsoft's new browser. Here is how to use it anyway:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9e9a5a9cd4d0f3757b7368de12fff0a5/00d43/Microsoft-Edge_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACx0lEQVQozzXP7UsTARwH8PsX0iyxNZ/SiSLkcIZURAaGSS6N1CAiJMRe9KYMizK0sCiifNEyInd3ujXINadzTtyD2+3utrbzAR+23JTb3aan7ry77XyhIBpN+rz5vvt9vz+AYRhRFBOCwHFcKBQiyQhF0RE6+i9oOhqN0jRNUdRqSjAYXF5eDgQCi/PzIbMZQDHs46deEIRwHPd4PH7fb58XJxy2aQ/u8XpdTieOe/x+Ih6PcxwnCEIikeA4bptlxZkZAILhR4/bX3R2trbeb25ufNj+8v3XX68+DEDa0SHDmNk8bjAYTaaxSArDMIIgiCnJ3V1Ao9EYh4dBEMw4nna3pe1Z78hzlfW7wTu3GAr+WQ4sLaIo6nIisdgax3Esy/I8v729zTAMG48DEARptVoIht+8fTeJLf0w+63YQniFXF1ZiUWpcCiIoW6/37+xsRGPxxMpPM8fXQFgGAZBcGTUND5hdbgwKkJ6cHyamEZxbyAQWFpamJ2dIQj/+vr61tZWIpFIphwtB0AQ1Gg0oyYTgqIUGabIFRT3uj2E3YU7nG7blNs0MTVhczMMw/G8uLOTFMXkf4DFYtHr9S4X4kIQtbq/u7vrSUeH6htELIZ9cwtWJzZsnjSY7TQZiUZIJhYTWDb5vx/AMAxBEIvFUl6uKJTJ5GVyySlp1eUrr3t61CDsm55FMMxps5Km8dVJa8TmWLM7+Ngan/ocQBDEZrPrh35mSzOPpaXn5GTn5eTICmWS03kVlRdrlcrmO/eednaxm5tJjtvf2zvc3z88ODhMAXw+H0EQRqOhQJaVfSZLWiCRFkhkpYXySkXNjVplY0NDU2PLgzb14KBGp4O1WmhgQA32f1apvvT1AW43gqKYTqfNzU0vkp0okRcUK4rOVVVU19dcb6pT3r5Zd6v+WoPy0tVqxYXzxWVni0pL8mX5GZkns6SSv6Fr7V7KdyDTAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e9a5a9cd4d0f3757b7368de12fff0a5/e4706/Microsoft-Edge_04.avif 230w", "/en/static/9e9a5a9cd4d0f3757b7368de12fff0a5/d1af7/Microsoft-Edge_04.avif 460w", "/en/static/9e9a5a9cd4d0f3757b7368de12fff0a5/7f308/Microsoft-Edge_04.avif 920w", "/en/static/9e9a5a9cd4d0f3757b7368de12fff0a5/445fc/Microsoft-Edge_04.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9e9a5a9cd4d0f3757b7368de12fff0a5/a0b58/Microsoft-Edge_04.webp 230w", "/en/static/9e9a5a9cd4d0f3757b7368de12fff0a5/bc10c/Microsoft-Edge_04.webp 460w", "/en/static/9e9a5a9cd4d0f3757b7368de12fff0a5/966d8/Microsoft-Edge_04.webp 920w", "/en/static/9e9a5a9cd4d0f3757b7368de12fff0a5/a5d4d/Microsoft-Edge_04.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e9a5a9cd4d0f3757b7368de12fff0a5/81c8e/Microsoft-Edge_04.png 230w", "/en/static/9e9a5a9cd4d0f3757b7368de12fff0a5/08a84/Microsoft-Edge_04.png 460w", "/en/static/9e9a5a9cd4d0f3757b7368de12fff0a5/c0255/Microsoft-Edge_04.png 920w", "/en/static/9e9a5a9cd4d0f3757b7368de12fff0a5/00d43/Microsoft-Edge_04.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9e9a5a9cd4d0f3757b7368de12fff0a5/c0255/Microsoft-Edge_04.png",
              "alt": "Microsoft Edge Download",
              "title": "Microsoft Edge Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the 3 dots in the top right corner of the Edge browser and select Open with Internet Explorer.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/409bdc6ccfcaebea7e70203aba451940/00d43/Microsoft-Edge_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACLElEQVQoz4WSy2sTURTG509xYU1DoptiaaEhFGrVUg2IoisRaXwgabsQa1EidaFLXbjWrqVWkJAQKu4MbZQ0qU3IY9JkGJtMZpLJzJ3Hnde9VybTRFz546zO4TuPj0OxLMtxXKfTEQShVqsdDajX6/SQ+oCTZK1C03S1Wi2Xy3SNporFommaqq6pqgYhxBgTQmzbNk1TG2DbtptHCGNim8qvg/zu7l42m83lctTOzleEEBmCMXYQctA/eB0HZfJhczMef7H+7PmTtadUOp32aghjCSiSDAzTshzHtG3LDce0bFWHZKj/tLUVvR+7cu3W7PwilUqlPDHGWOhJx+2eqrrbQmiYhqnrEBqGquuj7d68fXfj5u2FyPXw3GUqmUyeiAnhBf430+RaLZZhuFZLAbIGAEaODuFIvPHy1cWFSCg8NzkdohKJxHAyEhWjLdttUeP6bvCyIaioB4miu4Z54vjG6/lLkZnwhamZWSqTyeChIflK8/3nb4eszPQg27eOesaPNv7ZJqIKCUbeaR+3v8RWVh8+iN5bukMVCgXHcbyuFVZIZEr7DbHASKVjleZgkceHIpE0w5uMMUklttfWHy9GrobCYSqfz3v3OI4jAlWBVq8PhL7M82Lp+35l76DJsF2xP3L7UWxlcmr6jD9w6rTPfZKR27KiSLIMFAAUBQAg1Bt8g+l2u6qmjR5heXV54vxE4FwwcDboTv77A/8DY3I3ujQe9I/5fWPjvj8iDV2neMMOngAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/409bdc6ccfcaebea7e70203aba451940/e4706/Microsoft-Edge_05.avif 230w", "/en/static/409bdc6ccfcaebea7e70203aba451940/d1af7/Microsoft-Edge_05.avif 460w", "/en/static/409bdc6ccfcaebea7e70203aba451940/7f308/Microsoft-Edge_05.avif 920w", "/en/static/409bdc6ccfcaebea7e70203aba451940/445fc/Microsoft-Edge_05.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/409bdc6ccfcaebea7e70203aba451940/a0b58/Microsoft-Edge_05.webp 230w", "/en/static/409bdc6ccfcaebea7e70203aba451940/bc10c/Microsoft-Edge_05.webp 460w", "/en/static/409bdc6ccfcaebea7e70203aba451940/966d8/Microsoft-Edge_05.webp 920w", "/en/static/409bdc6ccfcaebea7e70203aba451940/a5d4d/Microsoft-Edge_05.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/409bdc6ccfcaebea7e70203aba451940/81c8e/Microsoft-Edge_05.png 230w", "/en/static/409bdc6ccfcaebea7e70203aba451940/08a84/Microsoft-Edge_05.png 460w", "/en/static/409bdc6ccfcaebea7e70203aba451940/c0255/Microsoft-Edge_05.png 920w", "/en/static/409bdc6ccfcaebea7e70203aba451940/00d43/Microsoft-Edge_05.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/409bdc6ccfcaebea7e70203aba451940/c0255/Microsoft-Edge_05.png",
              "alt": "Microsoft Edge Download",
              "title": "Microsoft Edge Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Your camera´s web user interface will now be loaded inside Internet Explorer 11. Type in your username and password to login.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bb4bf9a48f16a020838175cfdba26f53/00d43/Microsoft-Edge_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC50lEQVQozwHcAiP9AO7u7tbW1uTk5PDw8Pr6+vr5+Pjw7/vv7/fz8e7u7ePj4+np6e/v7/Hx8e/v7/j4+Pr6+vn5+fLy8vLw6wCPkJBUVlaBgYGpqKmanJuelJSwjJKue4GsmZ6lqauioqOpqaqsrK2urq6xsbGvr6+mpqaqqaqrq6qsrKoAoKCgqamqh4aFIB8cPT9CbX2Xe5K0gZSytLrFvsDGx8fHvb2+tLS2u7u90tDPvbu5iouKcnJ2Wl5hYWVoAJqamrW1tXp7ej09PJ2ouYWewY6jwMXN2N7d3eHg39TU0sDAwMLCw9za2NPRz7+9vJKSlGVnbkhNVE5TWQCcnJysrKykpaaytbm5w9PHzNTV19vf3t3V1dbk4+Db2tfKysnPz8/W1NGur66Jio5jZWtUV11BREpKTVIAnp6epqamtLW1tLW30c/N3t3ZtLa4wMHDwMDDzMzNy8zNxsfKysnJy8rHnqCjeXt/XV9jTE5TNTg9REZLAKKioqmoqLm6u9LU093c2crLycHDxMLIz7/Fy73Aw7W5v6yyuqmxup6nso6VoHd7hWZrdVZZXkJESFJUWAC8vLy6urrAwcGOj49paWpVVVRGR0qDh4vM09fN0NPIzM++wMO0usCstL2jqrOYoKmsrqyCg4FpaGJ1dG0Au7u6yMjIkpGRCwoKIyMkMC4vISAiQD8/VFVWgoOFr7O0rK+xra6uo6WikpOSgYSGgYF8ZWdlNDg7Nzo9AL+/v8fHyJSTkBQUDhcaGhwdGSIhHyMjIhsdIjQ3OTY6OklPUWNqbl9maU5UV0BGSjo9QDc5OCMmKDc6PADIyMjV1daLioYQEQEfJBcZIRYaIxcoMSk3QTpOVk9RWldSWVZUW1pRWVhGTU05QUI7Pz41ODQcHx0xMzIAwcHBycnJmZmXRUM4V1ZKV11TVl5UYWlhanJsbXRubXRwbHFuanBtaG1sYmdmXGBgWl1cV1pYS09NXF5duHCXIr0HWCYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bb4bf9a48f16a020838175cfdba26f53/e4706/Microsoft-Edge_06.avif 230w", "/en/static/bb4bf9a48f16a020838175cfdba26f53/d1af7/Microsoft-Edge_06.avif 460w", "/en/static/bb4bf9a48f16a020838175cfdba26f53/7f308/Microsoft-Edge_06.avif 920w", "/en/static/bb4bf9a48f16a020838175cfdba26f53/445fc/Microsoft-Edge_06.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bb4bf9a48f16a020838175cfdba26f53/a0b58/Microsoft-Edge_06.webp 230w", "/en/static/bb4bf9a48f16a020838175cfdba26f53/bc10c/Microsoft-Edge_06.webp 460w", "/en/static/bb4bf9a48f16a020838175cfdba26f53/966d8/Microsoft-Edge_06.webp 920w", "/en/static/bb4bf9a48f16a020838175cfdba26f53/a5d4d/Microsoft-Edge_06.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bb4bf9a48f16a020838175cfdba26f53/81c8e/Microsoft-Edge_06.png 230w", "/en/static/bb4bf9a48f16a020838175cfdba26f53/08a84/Microsoft-Edge_06.png 460w", "/en/static/bb4bf9a48f16a020838175cfdba26f53/c0255/Microsoft-Edge_06.png 920w", "/en/static/bb4bf9a48f16a020838175cfdba26f53/00d43/Microsoft-Edge_06.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bb4bf9a48f16a020838175cfdba26f53/c0255/Microsoft-Edge_06.png",
              "alt": "Microsoft Edge Download",
              "title": "Microsoft Edge Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you have activated the MJPEG stream before, please click on the h.264 / MJPEG toggle again to go back to the h.264 mode (h.264 has to be red).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/699c2274884b6e9c790bd00a76e5ee50/00d43/Microsoft-Edge_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACe0lEQVQoz1XQzU/aABjHcc4eliVDhhwkxoyXugqmEov0nZZCSysIwRkPzm2GWKuJB5CbF/8GD0WEJfTqYS7i28ETRqouxihevC4LIS4uWIinzW6L2+fw3L7Jk5/p5uamfn3daDTuW632/b3ebnc6HV3XW63WN0Oz2dR1/fb2ttPpPDw8tNvtu7sfzWbj7vtX08y799NvZ5R8vryxsbG2ViyVVFVVFGV9ff2joVwuq6r6+6qqurm5+fnR1tbWJ1OxWNze3k6lUsPDwxiGIQgS+As14DiOIAiKoiRJDg0NraysXFxcHB8fa5pmyufzBwcHLMvCMDw6Our1ej0GCIKcTqfD4RgAALfL5XI6PYODdrs9k8nU63VN005PT02Kouzv70ciERAEY7FYMpmMGxKJBEVRDMNAPp/DDbwe9Lrcbkt3dy6Xu7y8rNVqJycnpkKhUKlUOI4DQRDDMNxAEASO4xRJYmQwScBp+pUUAvwDrucvzLnl5ae4VCrt7OwIgsAwTDQaFQSB4zhRFHmeFwRBHBsbj3IfJuLTcf5NYjyAoNls5urq6k9cqVSq1WosFhNFcWpqKpVKTRgmJydZQ5Bh6HCYYtgIx0EQlM1mn+Ld3d1arSYIgsfjRVHc2BULBBAMw0ZGRiAIetypt9du7+3r6zObzcv/vr23t6dpWjwepyicZelwmAmFgqFQkKaDLMvyPE8QBAzDPp8PQRAAAP4brFqtnp19oWkSBJ8hiBWGLX6/xe9/2d/f09Njs9lsFoul22C1Wru6upaWlp7ePjw8PDo6Wl1dnZ1NS9KCJMmSJM/NyfPz8rxBluUFw+LiYjqdLhQK5+fnmqb9in8CYMxH7XW9rQUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/699c2274884b6e9c790bd00a76e5ee50/e4706/Microsoft-Edge_07.avif 230w", "/en/static/699c2274884b6e9c790bd00a76e5ee50/d1af7/Microsoft-Edge_07.avif 460w", "/en/static/699c2274884b6e9c790bd00a76e5ee50/7f308/Microsoft-Edge_07.avif 920w", "/en/static/699c2274884b6e9c790bd00a76e5ee50/445fc/Microsoft-Edge_07.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/699c2274884b6e9c790bd00a76e5ee50/a0b58/Microsoft-Edge_07.webp 230w", "/en/static/699c2274884b6e9c790bd00a76e5ee50/bc10c/Microsoft-Edge_07.webp 460w", "/en/static/699c2274884b6e9c790bd00a76e5ee50/966d8/Microsoft-Edge_07.webp 920w", "/en/static/699c2274884b6e9c790bd00a76e5ee50/a5d4d/Microsoft-Edge_07.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/699c2274884b6e9c790bd00a76e5ee50/81c8e/Microsoft-Edge_07.png 230w", "/en/static/699c2274884b6e9c790bd00a76e5ee50/08a84/Microsoft-Edge_07.png 460w", "/en/static/699c2274884b6e9c790bd00a76e5ee50/c0255/Microsoft-Edge_07.png 920w", "/en/static/699c2274884b6e9c790bd00a76e5ee50/00d43/Microsoft-Edge_07.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/699c2274884b6e9c790bd00a76e5ee50/c0255/Microsoft-Edge_07.png",
              "alt": "Microsoft Edge Download",
              "title": "Microsoft Edge Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now simply follow the instruction inside the webUI. First click on ActiveX Plugin to download the control element.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ffe394cbbf30f80d8d3bc330b52e93ef/00d43/Microsoft-Edge_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADFElEQVQoz0WRbU/bdhTF8wnWNVSFFxurBo2iOE5c59F2wXbcJB7JP41AUUHtxxgJD1O/CSCI4hIXzBACFGBjCc4IsfPQqlVflGqDUAKZISgNhFKYNqdU/enq6Lw5ukf3ahqNRvng4PDw8OTk5OO5yscLlfPz81qtpihKtVo9PT1tNBr1ev3q6ury8vLTp4uzs8bpB0XDP38+8PhJODLE8/yvz57FYzFhbk4QBI7jeJ6Px+MzMzNzTQRBmJ+fX1hYWF5eXlpaXlpc0MRisYmJCY7jGIbpun+fpmmKokiSpCiKpmnXFyiKcrlcJEm63e5kMpnNZjOZjGZsbCwajQqCgCBId3e3xWJBEASGYRRFzWazXq83GAwmkwmCDCYTbDKZYBgWRbFYLMqyrJmcnOQ4bnZ21mAwkCQJAAgEAn6/PxAIeL1eHMcJgtDrIZ0OMhrN7e3fQxCUTqfz+bwkSZrx8fGpqal4PG42mwmCYBjG3YRlWY/Hw7Ks18t6PCgABpo237nzo9FoFEXxOhyNRnmen56exjDM3wQA8FkB8AeDQQDAw4c/PXrk9/k8PT0+p9OZSqUKhYIaXl9fX11dXVxcJAgiFAr19/eHQqHe3t6BgYG+vr6uri6GYUiSfvDA43KppaxW28bGxnU4mUyura0lEgkEuWe3OzAMxzDc6cQwDMdx3G63OxwOCII6Ozt1Ol1HR8fng13X3tzcTKVSKysrOI4Fgz0+nxsAFgDW7/f2NAEAOBwOm81msVhsNpvVav26OZPZkiQpkUjcvfsdYv4WRVvQe1oU1cJwS1tba1tra+vt21rtTa325q1bLTdufPNDe7soitdhURSTydRvv/8xMvr058GhcGQkHBluzkhkSPWDkeGI6kfDQ6OD4eHRX56m/9zK5fLqnyVZzma33r4WD0uFSql4sJMv7+RV/Tu3/1euvFOo7L2ovH/5T6molF4clV8p+y/fvUnL0pYs5zSyinSilCrl3Up55/3u9t7u9rGyX68p1aNyvaZcXZ79e1H/sP3m6HWxWtmrHh8cK/v5fO7/2v8BsYm3plGmy8EAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ffe394cbbf30f80d8d3bc330b52e93ef/e4706/Microsoft-Edge_08.avif 230w", "/en/static/ffe394cbbf30f80d8d3bc330b52e93ef/d1af7/Microsoft-Edge_08.avif 460w", "/en/static/ffe394cbbf30f80d8d3bc330b52e93ef/7f308/Microsoft-Edge_08.avif 920w", "/en/static/ffe394cbbf30f80d8d3bc330b52e93ef/445fc/Microsoft-Edge_08.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ffe394cbbf30f80d8d3bc330b52e93ef/a0b58/Microsoft-Edge_08.webp 230w", "/en/static/ffe394cbbf30f80d8d3bc330b52e93ef/bc10c/Microsoft-Edge_08.webp 460w", "/en/static/ffe394cbbf30f80d8d3bc330b52e93ef/966d8/Microsoft-Edge_08.webp 920w", "/en/static/ffe394cbbf30f80d8d3bc330b52e93ef/a5d4d/Microsoft-Edge_08.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ffe394cbbf30f80d8d3bc330b52e93ef/81c8e/Microsoft-Edge_08.png 230w", "/en/static/ffe394cbbf30f80d8d3bc330b52e93ef/08a84/Microsoft-Edge_08.png 460w", "/en/static/ffe394cbbf30f80d8d3bc330b52e93ef/c0255/Microsoft-Edge_08.png 920w", "/en/static/ffe394cbbf30f80d8d3bc330b52e93ef/00d43/Microsoft-Edge_08.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ffe394cbbf30f80d8d3bc330b52e93ef/c0255/Microsoft-Edge_08.png",
              "alt": "Microsoft Edge Download",
              "title": "Microsoft Edge Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Confirm it´s installation by clicking on Run.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4b03f572295f8ec1e58abb718f9767a4/00d43/Microsoft-Edge_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC5klEQVQozy2S22sTURDGv93UFm8oaDVpNnvJ3rKb3WSTNNkkTbZpU+ulUqtPIgg+FS+NWN/Et4I3RMEHH6qIVbH6IAiCf4UKKmpB613EFmtaUVuqUp0k8DHMOcxvZs6ZAXgNggYxgrBB0mzoMUSc5fGMP5Xl2nNytqDnO81CKVosGcWSXujicp2rXW9ZxgMkg0jidZIUgRKF6bBOpjnhrkxk1qdcf3s25Ob5XHGd661xvRWZoi9dbCFYjBAjaOBVCBojRZhwhNEt1owxUYe1EsvsZFM83ZzuaEl3MKn82lyn6JWV0iatqxcIm1Sc6uuN/mUDqgXdhlazugUzztjJ9e1ZqdBpd/fGypudni1CqReQTMhRyFFGscFr3fsOjIxeO35hdOTS9ZNjt06MjZ++evPs2PjZ67c2795jdRREr7vNK7cWy6tyXaiRJmSTUSxIqjhQ2Xp8vPfYpW0jN7afut1/7k7/hbv9F+/1jd4P79q72rJb0vnmrNfkeky6CPpe1YJm+Yw42qQzV659mfn+eOLVxOS7F6/ePno28fzdx0/fZn8s/tlSOYKI7Ut3oD1PSuVBg6GH2b5oApx8fuzG0tLS9PT0XLU6Nzc7/eH91IMHXyff/Fz8u3VomMKSeSRzRBKsWo3KpoOgdObylaXfv6vV6sLC/K/5hZmpmU8Pn75+8nLy82zPYAVK1Bd3EXPhZOHk0NgN2WTUKAQ1tm1g9+GjA/uHdu4fGjhQ6Rus9B06uv3Q8I6Dw/5CGVqMMZMwU7DSsDKAoEKq7QZtiI6AiHUBtAbRymEjjw0cOX4eAYHGqcWg2tDiMGopwMk0XkEFLyMkMyGZFVRWUFhRYyWd5RVW0FgpwoYNhho0atOxoMagOQAXRkgmG5RIAQFtItn6DR0lKhBSqEB9i0SDlS0oNii0Hh0QqD1/iLQxRJd1x8+TH6ynUOtqTWSbFAuNaIrjaqoDNYZeHmzwfqHGhxGUwSksr/3P8g/OE8N//HMiwAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b03f572295f8ec1e58abb718f9767a4/e4706/Microsoft-Edge_09.avif 230w", "/en/static/4b03f572295f8ec1e58abb718f9767a4/d1af7/Microsoft-Edge_09.avif 460w", "/en/static/4b03f572295f8ec1e58abb718f9767a4/7f308/Microsoft-Edge_09.avif 920w", "/en/static/4b03f572295f8ec1e58abb718f9767a4/445fc/Microsoft-Edge_09.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4b03f572295f8ec1e58abb718f9767a4/a0b58/Microsoft-Edge_09.webp 230w", "/en/static/4b03f572295f8ec1e58abb718f9767a4/bc10c/Microsoft-Edge_09.webp 460w", "/en/static/4b03f572295f8ec1e58abb718f9767a4/966d8/Microsoft-Edge_09.webp 920w", "/en/static/4b03f572295f8ec1e58abb718f9767a4/a5d4d/Microsoft-Edge_09.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b03f572295f8ec1e58abb718f9767a4/81c8e/Microsoft-Edge_09.png 230w", "/en/static/4b03f572295f8ec1e58abb718f9767a4/08a84/Microsoft-Edge_09.png 460w", "/en/static/4b03f572295f8ec1e58abb718f9767a4/c0255/Microsoft-Edge_09.png 920w", "/en/static/4b03f572295f8ec1e58abb718f9767a4/00d43/Microsoft-Edge_09.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4b03f572295f8ec1e58abb718f9767a4/c0255/Microsoft-Edge_09.png",
              "alt": "Microsoft Edge Download",
              "title": "Microsoft Edge Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click Yes to start the installation routine.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bc8ee779dab6e8839a94d8131006cbd3/00d43/Microsoft-Edge_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACoUlEQVQoz2WQXUxSYRjHX4abNrNa3VZ+dGPLstm6KHPlB4dZWxeVWh5O4toAm3D8mB/NcWMKgtPmPALnKOicc3PN9CJDJKZnonxkxkYyu9IuwNZSKBEQ8LztQF712//mufi9z/P+wfT0O5VKTQ0Pd3crUBR9LhCgKIphmECAPk3w7JjqY1AUFQgEcrkcTExMjIyM0DQtEomycy69xJtb217JGlqaW+WdXcqeXrVCpVKq1MkoelTJ8VbhHT6CsLJer19eXpaIxbmXrxTcrLua1yIU9tbWds7MWCGE0QSxWCwajYbD4UgkAiHEhEIE4YHR0VGCIBYWzGKx6PyFzNQT9wFo5PFel5c39fUZgwd7W1vfvV6f1+vz+Xxer3d7e/sgFHpSWVVWWspuHhsbs1gsEokkKzsnPaOCw2lOT2/hcNoHBmx+/86PnZ+BQMDv9+/+2vX7A8FgkGHg44oKVh4fHzcYDGbzR4lEci3/+slTlQB0cLk9AMg0miUI4f5+MBKJhMPhUCh0eBiFCepxnP0zTdOzs7MOh1MqrS8qunvmbDUA0pSUdgDkJLkKIYzFjuB/4I1NfD4fWK1Wk8m0trbW2NCQlZ2TcfoFlytOTa0DoI2iVhOFxRkIGYZhjtgk5UfJs202m9FodLlcOC7Lzc1PS6sC4CGXiwEgJoilf3ICCGHyFbbtmhoejwccDofZbHa73bhMWnAjr/xBV1lJB8JTFd4empv7BiGMx9mzmVA4tvc7/icIEzKKYQiCAKfTubKy4vF4RCLRxcxzdufq5uaG+6trw+P6vP7JZnfY7Xbnl3XL5NtJxZv3GoPpg9GyuHivpKS4uJgtbH5+nqbpqakppVI9SGgHCU0i2iGtTkuSyegoiqQoHUXpSJKkqP7+fr1e/xemqLC2rpnMvQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc8ee779dab6e8839a94d8131006cbd3/e4706/Microsoft-Edge_10.avif 230w", "/en/static/bc8ee779dab6e8839a94d8131006cbd3/d1af7/Microsoft-Edge_10.avif 460w", "/en/static/bc8ee779dab6e8839a94d8131006cbd3/7f308/Microsoft-Edge_10.avif 920w", "/en/static/bc8ee779dab6e8839a94d8131006cbd3/445fc/Microsoft-Edge_10.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bc8ee779dab6e8839a94d8131006cbd3/a0b58/Microsoft-Edge_10.webp 230w", "/en/static/bc8ee779dab6e8839a94d8131006cbd3/bc10c/Microsoft-Edge_10.webp 460w", "/en/static/bc8ee779dab6e8839a94d8131006cbd3/966d8/Microsoft-Edge_10.webp 920w", "/en/static/bc8ee779dab6e8839a94d8131006cbd3/a5d4d/Microsoft-Edge_10.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc8ee779dab6e8839a94d8131006cbd3/81c8e/Microsoft-Edge_10.png 230w", "/en/static/bc8ee779dab6e8839a94d8131006cbd3/08a84/Microsoft-Edge_10.png 460w", "/en/static/bc8ee779dab6e8839a94d8131006cbd3/c0255/Microsoft-Edge_10.png 920w", "/en/static/bc8ee779dab6e8839a94d8131006cbd3/00d43/Microsoft-Edge_10.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bc8ee779dab6e8839a94d8131006cbd3/c0255/Microsoft-Edge_10.png",
              "alt": "Microsoft Edge Download",
              "title": "Microsoft Edge Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click Next to start the installation wizard.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/40320038c29045c32759599b8b10fb67/00d43/Microsoft-Edge_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACVUlEQVQoz2WQy08aURhH+aNsIpTQhBqJQFrdtJK04VU1EotdQEis27pQ24VEwsvH2CGy1EYaJSkseUkT0AwWm9LGuROYUe4wMs5cQKYdlNrHycldfMnJL7mKSqVSLpcZhrnieSTKtFothJAgCDRN0wxThxAh1Gg0EELtdlsURZ5vsizLQVIxMTlls9vX1tejW1ubPt/G5mYkEgkFg+FwGMOwLQyL4Pj29jaO4/h7PBqN7uzsxGKxPZkPilAotLu7azKZBgeVeoNhRK/XG4w9DQaj8cYR+WZ8Ov5EpVK5XK5CoZBKpTKZrCIcDicSCY1G43A6zwBVKBQr338AijoDgATye0aCGs2cfv3mXcWU9zUv7PZSqZTL5fL5vLycSCTUarXb7ZYkiSRBg+OazSbHccKV0EJIFERJks7P64uLoYF7qsnJCYIgstns4eGhvLy/v/9Ao5l56ex0rgEA1R4URTEMw7IshLDeo3PdGR0bs5jNpVLpNsYwLB6Pa7Xaufn5riRdXFywLNvowf4BrNf5K2FiaspmsxK/41gslk6ndTrdm4WF6273Jv4fCKEginJstd4tHxwc5PN5rVY745yV+st/dyzsx48ej1osln/iz7rh4bn515IkQVjnuEZf+ecueZ5rNrlLrtVuPzeb7TbbXZzJZI6OjpXKgWnHMwG1AAWqtVq1VqvRNAnA6Zfy8cdPR3vxCkGQ1erDoSHT+PjJycltnEwm0+m0x+OZfTW7vrHhDwQCweCNPr9/xetdWX7nXX676vX6/IFph2N5aalYLOZyuV/xT5LWDI7Sq8NGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/40320038c29045c32759599b8b10fb67/e4706/Microsoft-Edge_11.avif 230w", "/en/static/40320038c29045c32759599b8b10fb67/d1af7/Microsoft-Edge_11.avif 460w", "/en/static/40320038c29045c32759599b8b10fb67/7f308/Microsoft-Edge_11.avif 920w", "/en/static/40320038c29045c32759599b8b10fb67/445fc/Microsoft-Edge_11.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/40320038c29045c32759599b8b10fb67/a0b58/Microsoft-Edge_11.webp 230w", "/en/static/40320038c29045c32759599b8b10fb67/bc10c/Microsoft-Edge_11.webp 460w", "/en/static/40320038c29045c32759599b8b10fb67/966d8/Microsoft-Edge_11.webp 920w", "/en/static/40320038c29045c32759599b8b10fb67/a5d4d/Microsoft-Edge_11.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/40320038c29045c32759599b8b10fb67/81c8e/Microsoft-Edge_11.png 230w", "/en/static/40320038c29045c32759599b8b10fb67/08a84/Microsoft-Edge_11.png 460w", "/en/static/40320038c29045c32759599b8b10fb67/c0255/Microsoft-Edge_11.png 920w", "/en/static/40320038c29045c32759599b8b10fb67/00d43/Microsoft-Edge_11.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/40320038c29045c32759599b8b10fb67/c0255/Microsoft-Edge_11.png",
              "alt": "Microsoft Edge Download",
              "title": "Microsoft Edge Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click Install to continue.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7b8754d6feff90c8164f9f5693e41f68/00d43/Microsoft-Edge_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACWUlEQVQoz22SO0wacRjA/wMOLs66m7gYO5AwYB9DqZionPKwJDaipoONpuZoTJrWDnRSUimRcFfCcakXBwcGDElPQQxpWh8HiZGUR2MHBqC87jgb4NDjjhZsQmz9DV++5ZffN3zg/PxHNBbL5nKVcpmrVKocd3l5Wa1Wy+VyLpfL5/MMw9RqNZZlr1pwHHdx8YumCxelDDDMzBpmZtEPjo9Op/3dOoKiGIZtbNgQBHG0wDAMx/HrSRCE2+32er07TTyAIAi/36fVagfvDk490U9OarRajUajVqlUEASp2kAQBEmlUpPJFI1GKYoKh8PA6XQGAoGhoYf3H4wYZtYWF98vL1s9ngOaLqRSqWw2m2mSTqfTxWJRqVQuLS0lEom27PP5R0eHe3r6AdAPDKxMT78xm3d4vprN5dkSWyqVWJZlGIbneaVSCcNwW3a5XCS5OzY23N1zB4BnXV0vOztfrK9/bTSuaLrEcZwoCvV6XRBEQRAUCsUNeXNzkyR31WpVX989AOYlkjUAYKv1oNFo8LzQaCGK4vViMBhgGI7H439lkiQPD490uvH+fgUAcx0dzwF4ZbV+uVXW6/U35L29vVAoPDEx0t0tB+CpRDLXKgf/l0VR/Pdsn89HUSGdTtXb+wiAcYnkMQDzFsvnW8tqtdpoNLbLR0eHZ2cRuVw6NaWhqO/7+8d+/2kslqTpQvO/Mj+/fQqcevfzyWS+WJTJZAsLC+1yMBg8OTk2md6urLze3t4iCHxrC8cwh91uRxAEsdutq2bLqhmx2VAUhWEYx/FIJBIKhf7IvwHja9nQaJqUiAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7b8754d6feff90c8164f9f5693e41f68/e4706/Microsoft-Edge_12.avif 230w", "/en/static/7b8754d6feff90c8164f9f5693e41f68/d1af7/Microsoft-Edge_12.avif 460w", "/en/static/7b8754d6feff90c8164f9f5693e41f68/7f308/Microsoft-Edge_12.avif 920w", "/en/static/7b8754d6feff90c8164f9f5693e41f68/445fc/Microsoft-Edge_12.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7b8754d6feff90c8164f9f5693e41f68/a0b58/Microsoft-Edge_12.webp 230w", "/en/static/7b8754d6feff90c8164f9f5693e41f68/bc10c/Microsoft-Edge_12.webp 460w", "/en/static/7b8754d6feff90c8164f9f5693e41f68/966d8/Microsoft-Edge_12.webp 920w", "/en/static/7b8754d6feff90c8164f9f5693e41f68/a5d4d/Microsoft-Edge_12.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7b8754d6feff90c8164f9f5693e41f68/81c8e/Microsoft-Edge_12.png 230w", "/en/static/7b8754d6feff90c8164f9f5693e41f68/08a84/Microsoft-Edge_12.png 460w", "/en/static/7b8754d6feff90c8164f9f5693e41f68/c0255/Microsoft-Edge_12.png 920w", "/en/static/7b8754d6feff90c8164f9f5693e41f68/00d43/Microsoft-Edge_12.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7b8754d6feff90c8164f9f5693e41f68/c0255/Microsoft-Edge_12.png",
              "alt": "Microsoft Edge Download",
              "title": "Microsoft Edge Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Klicken Sie auf Beenden um die Installation abzuschließen.`}</p>
    <p><img parentName="p" {...{
        "src": "./Microsoft-Edge_13.png",
        "alt": "Microsoft Edge Download"
      }}></img></p>
    <p>{`Laden Sie jetzt die Seite neu und bestätigen Sie das Ausführen des Plugins durch Klicken auf Erlauben.`}</p>
    <p><img parentName="p" {...{
        "src": "./Microsoft-Edge_14.png",
        "alt": "Microsoft Edge Download"
      }}></img></p>
    <p>{`Click Finish to finalize the installation.`}</p>
    <p>{`Now reload the page and confirm the execution of the plugin by clicking on Allow. The plugin should now load and display the live video. You right-click the Internet Explorer icon in your taskbar to pin it and make it available for you in the future.`}</p>
    <h3 {...{
      "id": "what-if-your-video-does-not-show",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-if-your-video-does-not-show",
        "aria-label": "what if your video does not show permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What if your video does not show?`}</h3>
    <p>{`If the plugin tells you that it is connected, but the video still stays black, you might have to switch the Display Mode of the ActiveX plugin. Simply right-click the black video area and choose the Direct Draw method! If the video still fails to load, please check your virus scanner, firewall or web guard (see related topics below).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/122841e687b7a2c38797fbaad73ac11e/00d43/Microsoft-Edge_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABZElEQVQoz5WRz07CMBzHu64jnDxrok8AKwSN8w8JiYeFk4LG11ATDz4CweDBCyREFkaIqxsX5YCJISkrjk7mnslsEyFTo3zSQ/Ntvr/vty24Jf2z84tq9ZpSOpnBOZ9w7gyHL4OBzZjjOJRSxpgX4vv+1POm7isw77u12k2lUslms+l0WpZljLEckolWJoMxTqVSXzoOyeU2wZ2mWZalaRpCCAAgCAL4HxBC0G63TdNsNpuJRAJCKC4CoRhT5icwmUyCVqvV6XTq9fqPyRBChBASRVGAsWRJkoLkXq/XaDSWrR2Yn0N0XY+ZgysBsLVX0A2rT4yr45PY6MA8Go0opYZhxMzRZmN947BUKqlqYXUNfk8ej8e2bRNCpNAMlqrtuq7v++SBICRFbYUFPh8MIXFBn5sZYxPOjW53Zdb7z8xoaPBVruu+ee/08eny6OC0sJ/bVnZ3fkVRlHw+r6pqsVgsl8sfN1qPMGe1H0sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/122841e687b7a2c38797fbaad73ac11e/e4706/Microsoft-Edge_15.avif 230w", "/en/static/122841e687b7a2c38797fbaad73ac11e/d1af7/Microsoft-Edge_15.avif 460w", "/en/static/122841e687b7a2c38797fbaad73ac11e/7f308/Microsoft-Edge_15.avif 920w", "/en/static/122841e687b7a2c38797fbaad73ac11e/445fc/Microsoft-Edge_15.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/122841e687b7a2c38797fbaad73ac11e/a0b58/Microsoft-Edge_15.webp 230w", "/en/static/122841e687b7a2c38797fbaad73ac11e/bc10c/Microsoft-Edge_15.webp 460w", "/en/static/122841e687b7a2c38797fbaad73ac11e/966d8/Microsoft-Edge_15.webp 920w", "/en/static/122841e687b7a2c38797fbaad73ac11e/a5d4d/Microsoft-Edge_15.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/122841e687b7a2c38797fbaad73ac11e/81c8e/Microsoft-Edge_15.png 230w", "/en/static/122841e687b7a2c38797fbaad73ac11e/08a84/Microsoft-Edge_15.png 460w", "/en/static/122841e687b7a2c38797fbaad73ac11e/c0255/Microsoft-Edge_15.png 920w", "/en/static/122841e687b7a2c38797fbaad73ac11e/00d43/Microsoft-Edge_15.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/122841e687b7a2c38797fbaad73ac11e/c0255/Microsoft-Edge_15.png",
              "alt": "Microsoft Edge Download",
              "title": "Microsoft Edge Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If the plugin tells you that it is connected, but the video still stays black, you might have to switch the Display Mode of the ActiveX plugin. Simply right-click the black video area and choose the Direct Draw method!`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      